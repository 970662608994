import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import {
	Button,
	Modal,
	A,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { agent } from '@redux';
import { constants, env } from '@config';

const useStyles = makeStyles(theme => ({
	title: {
		...theme.customstyles.subheading,
		color: theme.palette.text.primary,
		wordWrap: 'break-word',
	},
	content: {
		textAlign: 'center',
	},
	body: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		marginTop: '16px',
		wordWrap: 'break-word',
	},
	button: {
		paddingTop: '12px',
	},
	circularProgress: {
		color: 'inherit',
	},
}));

const AgentRegistrationFormModal = ({ onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = useState(false);

	const agencyagmt = (firm) => {
		switch(firm) {
			case constants.LIME_LME: return "https://j2t.global/files/limeltd/regulatorydocs/Refferal%20Agent_Agency_LIME.pdf";
			case constants.FIRM_J2TX: return "https://j2tx.com/files/j2tx/Referral%20Agent_Agreement_J2TX.pdf";
			default: "";
		} 
	};
	const agencyregulations = (firm) => {
		switch(firm) {
			case constants.LIME_LME: return "https://j2t.global/files/limeltd/regulatorydocs/Refferal%20Agent%20Regulations.pdf";
			case constants.FIRM_J2TX: return "https://j2tx.com/files/j2tx/Referral%20Agent_Regulations_J2TX.pdf";
			default: return "";
		} 
	};
	const agentRegistration = () => dispatch(agent.actions.agentRegistration.request({
		AgentRewardType: 'IB',
		ApplyAgreement: true,
	}));

	const submit = () => {
		setSubmitting(true);
		agentRegistration();
	};

	const btnTitle = <R id="shared_ok" defaultValue="OK" />;

	return (
		<Modal
			title=""
			onClose={onClose}
			className={`QA-${ModalPopups.AGENT_REGISTRATION_MODAL}`}
		>
			<div className={classes.content}>
				<div className={classes.progDescription}>
					<R
						id="agency_registration_agreement"
						defaultValue="By clicking OK you become our IB (Agent) and accept our <agencyagmt>Agency Agreement</agencyagmt> and <agencyregulations>Referral Agent Regulations</agencyregulations>."
						enableHtml
						components={{
							agencyagmt: <A href={agencyagmt(env.firm)} target="_blank"/>,
							agencyregulations: <A href={agencyregulations(env.firm)} target="_blank"/>,
						}}
					/>
				</div>
				<div className={classes.button}>
					<Button
						color="primary"
						variant="contained"
						fullWidth
						onClick={submit}
						disabled={submitting}
					>
						{submitting
							? <CircularProgress
							className={classes.circularProgress}
							size={18}
							/>
							: btnTitle
						}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default AgentRegistrationFormModal;
